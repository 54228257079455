import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9d6737ee = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _94411f2a = () => interopDefault(import('../pages/account/AccountDetails.vue' /* webpackChunkName: "pages/account/AccountDetails" */))
const _3579cd08 = () => interopDefault(import('../pages/account/Addresses.vue' /* webpackChunkName: "pages/account/Addresses" */))
const _7b39445c = () => interopDefault(import('../pages/account/ManageMarketingEmails.vue' /* webpackChunkName: "pages/account/ManageMarketingEmails" */))
const _87726a80 = () => interopDefault(import('../pages/account/MyBikes.vue' /* webpackChunkName: "pages/account/MyBikes" */))
const _13228d85 = () => interopDefault(import('../pages/account/MyOrdersReturnsApp.vue' /* webpackChunkName: "pages/account/MyOrdersReturnsApp" */))
const _60e4457c = () => interopDefault(import('../pages/account/OrderHistory.vue' /* webpackChunkName: "pages/account/OrderHistory" */))
const _377914cd = () => interopDefault(import('../pages/account/TrackMyOrder.vue' /* webpackChunkName: "pages/account/TrackMyOrder" */))
const _636032a3 = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _addbe780 = () => interopDefault(import('../pages/content-hub/index.vue' /* webpackChunkName: "pages/content-hub/index" */))
const _9c4186f0 = () => interopDefault(import('../pages/healthcheck.vue' /* webpackChunkName: "pages/healthcheck" */))
const _4ae75f2c = () => interopDefault(import('../pages/motorcycle-gear/index.vue' /* webpackChunkName: "pages/motorcycle-gear/index" */))
const _44ff35fc = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _6ab498fd = () => interopDefault(import('../pages/zip.vue' /* webpackChunkName: "pages/zip" */))
const _b1492a10 = () => interopDefault(import('../pages/content-hub/_slug.vue' /* webpackChunkName: "" */))
const _559081cb = () => interopDefault(import('../pages/brands/_id.vue' /* webpackChunkName: "" */))
const _4e54a1bc = () => interopDefault(import('../pages/motorcycle-gear/_slug.vue' /* webpackChunkName: "pages/motorcycle-gear/_slug" */))
const _17a0d45b = () => interopDefault(import('../pages/pages/_slug.vue' /* webpackChunkName: "pages/pages/_slug" */))
const _432c5ff3 = () => interopDefault(import('../pages/products/_slug.vue' /* webpackChunkName: "pages/products/_slug" */))
const _594b53a8 = () => interopDefault(import('../pages/stores/_id/_slug.vue' /* webpackChunkName: "pages/stores/_id/_slug" */))
const _bc4e6da4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _9d6737ee,
    name: "account___en",
    children: [{
      path: "AccountDetails",
      component: _94411f2a,
      name: "account-AccountDetails___en"
    }, {
      path: "Addresses",
      component: _3579cd08,
      name: "account-Addresses___en"
    }, {
      path: "ManageMarketingEmails",
      component: _7b39445c,
      name: "account-ManageMarketingEmails___en"
    }, {
      path: "MyBikes",
      component: _87726a80,
      name: "account-MyBikes___en"
    }, {
      path: "MyOrdersReturnsApp",
      component: _13228d85,
      name: "account-MyOrdersReturnsApp___en"
    }, {
      path: "OrderHistory",
      component: _60e4457c,
      name: "account-OrderHistory___en"
    }, {
      path: "TrackMyOrder",
      component: _377914cd,
      name: "account-TrackMyOrder___en"
    }]
  }, {
    path: "/brands",
    component: _636032a3,
    name: "brands___en"
  }, {
    path: "/content-hub",
    component: _addbe780,
    name: "content-hub___en"
  }, {
    path: "/content-hub",
    component: _addbe780,
    name: "content-hub___en"
  }, {
    path: "/content-hub",
    component: _addbe780,
    name: "content-hub___en"
  }, {
    path: "/healthcheck",
    component: _9c4186f0,
    name: "healthcheck___en"
  }, {
    path: "/motorcycle-gear",
    component: _4ae75f2c,
    name: "motorcycle-gear___en"
  }, {
    path: "/search",
    component: _44ff35fc,
    name: "search___en"
  }, {
    path: "/zip",
    component: _6ab498fd,
    name: "zip___en"
  }, {
    path: "/content-hub/articles/:slug",
    component: _b1492a10,
    name: "article___en"
  }, {
    path: "/content-hub/videos/:slug",
    component: _b1492a10,
    name: "video___en"
  }, {
    path: "/content/brands/:slug",
    component: _559081cb,
    name: "brands-pages___en"
  }, {
    path: "/brands/:id",
    component: _559081cb,
    name: "brands-id___en"
  }, {
    path: "/content-hub/:slug?",
    component: _b1492a10,
    name: "content-hub-slug___en"
  }, {
    path: "/motorcycle-gear/:slug?",
    component: _4e54a1bc,
    name: "motorcycle-gear-slug___en"
  }, {
    path: "/pages/:slug?",
    component: _17a0d45b,
    name: "pages-slug___en"
  }, {
    path: "/products/:slug?",
    component: _432c5ff3,
    name: "products-slug___en"
  }, {
    path: "/promotions/:slug",
    component: _4e54a1bc,
    name: "promotion___en"
  }, {
    path: "/content-hub/:filterLabel/:filter+",
    component: _addbe780,
    name: "content-hub-filtered___en"
  }, {
    path: "/stores/:id?/:slug?",
    component: _594b53a8,
    name: "stores-id-slug___en"
  }, {
    path: "/motorcycle-gear/:slug/:slug2?/:slug3?/:slug4?/:slug5?/:slug6?",
    component: _4e54a1bc,
    name: "categories-pages___en"
  }, {
    path: "/pages/:slug/:slug2?/:slug3?/:slug4?/:slug5?/:slug6?",
    component: _17a0d45b,
    name: "pages___en"
  }, {
    path: "/",
    component: _bc4e6da4,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
